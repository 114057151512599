import {attr, controller, target} from '@github/catalyst'
import {copyText} from '../command-palette/copy'
import verifySsoSession from '../sso'

const VALID_STATES: string[] = ['idle', 'fetching', 'success', 'error']
const TRANSIENT_STATES: string[] = ['success', 'error']

@controller
export class RemoteClipboardCopyElement extends HTMLElement {
  @attr src = ''
  @attr stateTimeout = 2000

  @target idle: HTMLElement | null
  @target fetching: HTMLElement | null
  @target success: HTMLElement | null
  @target error: HTMLElement | null

  transientStateTimer: number | null = null
  _state = 'idle'

  connectedCallback() {
    this.render()
  }

  get state(): string {
    return this._state
  }

  set state(value: string) {
    if (!VALID_STATES.includes(value)) return

    this._state = value
    this.render()

    if (this.transientStateTimer) {
      window.clearTimeout(this.transientStateTimer)
      this.transientStateTimer = null
    }

    if (TRANSIENT_STATES.includes(value)) {
      this.transientStateTimer = window.setTimeout(() => {
        this.state = 'idle'
      }, this.stateTimeout)
    }
  }

  private asyncClipboardSupported(): boolean {
    const hasClipboardApi = 'clipboard' in navigator
    const hasClipboardItem = typeof ClipboardItem !== 'undefined'

    return hasClipboardApi && hasClipboardItem
  }

  private async remoteCopy(): Promise<void> {
    if (this.src === '') return
    if (this.state !== 'idle') return

    const contentPromise = this.fetchContentWithState()

    try {
      // Try to set ClipboardItem to have a promise as content
      await this.asyncClipboard(contentPromise)
    } catch {
      // If promises aren't supported (Chrome), then wait for blob to be ready
      try {
        await this.awaitThenCopy(contentPromise)
      } catch (err) {
        this.state = 'error'
        throw err
      }
    }
  }

  private async asyncClipboard(contentPromise: Promise<Blob>): Promise<void> {
    if (!this.asyncClipboardSupported()) throw new Error('Async clipboard API is not supported')

    const clipboardItem = new ClipboardItem({
      'text/plain': contentPromise
    })

    // We are already checking for browser support earlier in this function so we can safely disable this rule
    await navigator.clipboard.write([clipboardItem])

    this.state = 'success'
  }

  private async awaitThenCopy(contentPromise: Promise<Blob>): Promise<void> {
    const blob = await contentPromise
    await copyText(await blob.text())
    this.state = 'success'
  }

  private async fetchContent(): Promise<Blob> {
    await verifySsoSession()

    const response = await fetch(this.src, {
      method: 'GET',
      redirect: 'follow'
    })

    if (!response.ok) {
      throw new Error('Fetching content failed')
    }

    let text = await response.text()
    text = text.replace(/\r?\n$/, '') // remove trailing newline

    return new Blob([text], {type: 'text/plain'})
  }

  private async fetchContentWithState(): Promise<Blob> {
    this.state = 'fetching'

    try {
      return await this.fetchContent()
    } catch (err) {
      this.state = 'error'
      throw err
    }
  }

  private render(): void {
    if (this.idle) this.idle.hidden = this.state !== 'idle'
    if (this.fetching) this.fetching.hidden = this.state !== 'fetching'
    if (this.success) this.success.hidden = this.state !== 'success'
    if (this.error) this.error.hidden = this.state !== 'error'
  }
}
